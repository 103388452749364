.notices {
    padding: 1px 1px 1px 30px;
    margin: 15px 0;
}

.notices p {

}

.notices.yellow {
    border-left: 10px solid #f0ad4e;
    background: #fcf8f2;
    color: #df8a13;
}

.notices.red {
    border-left: 10px solid #d9534f;
    background: #fdf7f7;
    color: #b52b27;
}

.notices.blue {
    border-left: 10px solid #5bc0de;
    background: #f4f8fa;
    color: #28a1c5;
}

.notices.green {
    border-left: 10px solid #5cb85c;
    background: #f1f9f1;
    color: #3d8b3d;
}